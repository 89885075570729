import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  modules: [],
  landingData: null,
  nagarpalikaInfo: null,
};

const appSlice = createSlice({
  name: "appData",
  initialState,
  reducers: {
    setModules: (state, action) => {
      state.modules = action.payload;
    },
    setLandingData: (state, action) => {
      state.landingData = action.payload;
    },
    setNagarpalikaInfo: (state, action) => {
      state.nagarpalikaInfo = action.payload;
    },
  },
});

const { setModules, setLandingData, setNagarpalikaInfo } = appSlice.actions;

const appReducer = appSlice.reducer;

export default appReducer;

export const fetchAppData = () => async (dispatch) => {
  if (process.env.NODE_ENV === "production") {
    try {
      const modules = await fetch(
        process.env.REACT_APP_PUBLIC_URL + "/applications.json"
      );
      const landingData = await fetch(
        process.env.REACT_APP_PUBLIC_URL + "/officials.json"
      );
      const nagarpalikaInfo = await fetch(
        process.env.REACT_APP_PUBLIC_URL + "/nagarpalikaInfo.json"
      );
      let jsonModules = await modules.json();
      let jsonLandingData = await landingData.json();
      let jsonNagarpalikaInfo = await nagarpalikaInfo.json();
      if (jsonModules) dispatch(setModules(jsonModules));
      if (jsonLandingData) dispatch(setLandingData(jsonLandingData));
      if (jsonNagarpalikaInfo)
        dispatch(setNagarpalikaInfo(jsonNagarpalikaInfo));
    } catch (error) {
      console.log(error, "error");
    }
  } else {
    try {
      let file = process.env.REACT_APP_PUBLIC_URL.split("/").at(-1);

      const modules = await fetch(`/${file}/applications.json`);
      const landingData = await fetch(`/${file}/officials.json`);
      const nagarpalikaInfo = await fetch(`/${file}/nagarpalikaInfo.json`);
      let jsonModules = await modules.json();

      let jsonLandingData = await landingData.json();
      let jsonNagarpalikaInfo = await nagarpalikaInfo.json();
      if (jsonModules) dispatch(setModules(jsonModules));
      if (jsonLandingData) dispatch(setLandingData(jsonLandingData));
      if (jsonNagarpalikaInfo)
        dispatch(setNagarpalikaInfo(jsonNagarpalikaInfo));
    } catch (error) {
      console.log(error, "error");
    }
  }
};
